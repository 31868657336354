import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Post {
  id: number
  slug: string
  title: string
  date: string
  snippet: any
  description: any
  images: {
    title: string
    gatsbyImageData: any
  }
}

interface Slug {
  slugs: string
}

const s = {
  container: `w-full h-fit-content`,
  title: `mb-20`,

  grid: `grid-2 gap-20 mdx:grid-cols-1`,
  group: `mb-0`,

  link: `w-full h-full flexcenter flex-row underline 
  transition-all delay-300 ease-out duration-500 hover:underlinered smx:flex-col`,
  image: `w-w27 h-h17 transition-all delay-500 ease-out 
  lgm:h-h21 mdm:w-3/4 mdm:h-h32 smm:h-h40 xsx:h-h32 smx:w-full`,

  body: `w-full h-fit-content flex flex-col pl-8 text-black smx:pt-8 smx:pl-0`,
  date: `mb-4 text-md`,
  snippet: `text-md leading-10`,
}

const Related = ({ slugs }: Slug) => {
  const datas = useStaticQuery(graphql`
    query {
      allContentfulBlogs(sort: { fields: date, order: DESC }) {
        nodes {
          id
          slug
          title
          date(formatString: "DD MMMM YYYY")
          snippet
          images {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              quality: 80
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      contentfulRelated {
        title
      }
    }
  `)

  const { title } = datas.contentfulRelated

  const posts = datas.allContentfulBlogs.nodes.filter(
    ({ slug }: Post) => slug !== slugs
  )

  return (
    <section className={s.container}>
      <h3 className={s.title}>{title}</h3>
      <div className={s.grid}>
        {posts.map(({ id, slug, date, snippet, images }: Post) => (
          <div key={id} className={s.group}>
            <Link className={s.link} to={`/post/${slug}/`}>
              <GatsbyImage
                className={s.image}
                image={images.gatsbyImageData}
                alt={images.title}
                loading="eager"
              />
              <div className={s.body}>
                <p className={s.date}>{date}</p>
                <p className={s.snippet}>{snippet}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Related
