import React from 'react'

import LinkButton from 'shared/Button/LinkButton'

const s = {
  container: `w-full h-fit-content`,
  list: `flex flexbetween flex-wrap list-none p-0`,
  items: `p-0`,
  button: `block invert`,
}

const Pagination = ({ page, prev, next }: any) => {
  const { prevPost, nextPost } = page

  return (
    <section className={s.container}>
      <ul className={s.list}>
        <li className={s.items}>
          {prevPost && (
            <LinkButton classname={s.button} to={`/post/${prev.slug}/`}>
              Prev
            </LinkButton>
          )}
        </li>
        <li className={s.items}>
          {nextPost && (
            <LinkButton classname={s.button} to={`/post/${next.slug}/`}>
              Next
            </LinkButton>
          )}
        </li>
      </ul>
    </section>
  )
}

export default Pagination
