import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Markdown from 'markdown-to-jsx'

import Seo from 'shared/Seo/Seo'
import Layout from 'shared/Layout/Layout'
import Pagination from 'shared/Pagination/Pagination'
import Related from 'main/Post/Related'

const s = {
  container: `w-full h-fit-content mx-0 mb-40 section`,
  wrapper: `wrapper`,

  image: `relative top-0 left-0 z-1 w-full h-h85`,

  card: `w-full h-fit-content my-36`,
  body: `w-full h-fit-content flex flex-col`,
  title: `-mt-4 mb-2 text-h3`,
  date: `mb-8 text-sm`,
  group: `mb-28`,
  description: `mb-16 text-lg text-justify leading-loose description`,
}

const Post = ({ data, pageContext }: any) => {
  const slugs = data.contentfulBlog
  const { prevPost, nextPost } = pageContext
  const { slug, title, date, snippet, description, images } =
    data.contentfulBlogs

  return (
    <Layout backgrounds="bg-black">
      <Seo title={title} description={snippet} />
      <section className={s.container} id={slugs.slug}>
        <GatsbyImage
          className={s.image}
          image={images.gatsbyImageData}
          alt={images.title}
          loading="eager"
        />
        <div className={s.wrapper}>
          <div className={s.card}>
            <div className={s.body}>
              <h3 className={s.title}>{title}</h3>
              <p className={s.date}>{date}</p>

              <Markdown className={s.description}>
                {description.description}
              </Markdown>
            </div>

            <Pagination page={pageContext} prev={prevPost} next={nextPost} />
          </div>
          <Related slugs={slug} />
        </div>
      </section>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogs(slug: { eq: $slug }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      snippet
      description {
        description
      }
      images {
        title

        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: NONE
          formats: [AUTO, WEBP]
        )
      }
    }
    contentfulBlog {
      slug
    }
  }
`
